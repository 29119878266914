@import "../mixins";
@import "../variables";

.tab {
    display: inline-block;
    padding: 6px 7px;
    background-color: #000000;
    color: black;
    font-weight: bold;
    width: 140px;
    cursor: pointer;
    border: 1px solid transparent;
    border-color: #fff;
    border-top-left-radius: 6px;
    border-top-right-radius: 13px;
    transition: background-color 0.3s;
    margin-top: 11px;
    font-size: 14px;
  }
  
  .tab:hover {
    background-color: #bebdbd;
    box-shadow: 0px -5px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .tab.active {
    background-color: #7b7676;
    box-shadow: 0px 0px 11px 1px rgb(6 6 6 / 50%), 0px -5px 0px 0px #C643F1;
  }

  .logout {
    float: right;
    margin-top: 27px;
    margin-right: 3px;
    color: white;
  }

  .logout:hover {
    cursor: pointer;
    color: #bebdbd;
  }

  .tabs {
    margin-left: calc( (100vw - 1600px) / 2)
  }

  .tabframe {
    border: 0;
    background-color: #7b7676;
    margin-left: calc( (100vw - 1600px) / 2);
    width: 1600px;
    height: 100vh;
    background-image: url("../assets/dl_logo_transparent.jpg");
    background-repeat: no-repeat, repeat;
    background-position: center;
  }

  .icon {
    float: right;
    margin-top: 20px;
    margin-right: calc((101vw - 1600px) / 2);
    cursor: pointer;
    height: 31px;
  }

  .pdficon {
    float: right;
    margin-top: 13px;
    cursor: pointer;
    height: 40px;
  }

  .pwrpicon {
    float: right;
    margin-top: 17px;
    cursor: pointer;
    height: 32px;
    margin-right: 8px;
  }

  .dividericon {
    float: right;
    margin-top: 19px;
    margin-right: 21px;
    height: 31px;
  }

  .toolbar {
    width: 100%;
    fill: white
  }

  .landing {
    min-width: 1600px;
    overflow: scroll;
  }

  @media all and (max-width: 1600px) {
    .tabframe {
        border: 0;
        background-color: #7b7676;
        margin-left: 0;
        width: 1600px;
        height: 100vh;
        background-image: url("../assets/dl_logo_transparent.jpg");
        background-repeat: no-repeat, repeat;
        background-position: center;
      }

      .tabs {
        margin-left: 0
      }

      .icon {
        margin-right: 0;
      }
 }