@import "../mixins";
@import "../variables";
@import "../extensions";

.form {
  display: -ms-grid;
  display: grid;
  width: 433px;
  margin: -17px;
  @include media-max-width() {
    width: 95%;
  }
}

input {
  @include input-placeholder();
  padding-left: 75px;
}

.input {
  @extend .mainInputStyle;
  border: 1px solid white;
  margin-top: $main-margin;
}
.loginBtn {
  @extend .mainInputStyle;
  border: 2px solid #a3d624;
  margin-top: 21px;
  font-size: x-large;
  color: #a3d624;
  height: 77px;
  cursor: pointer;
  &:active {
    opacity: 50%;
  }
  &:hover {
    filter: invert(35%);
  }
}
.icon {
  position: absolute;
  margin-top: $main-margin * 2;
  margin-left: $main-margin * 1;
}

.forgot {
  font-size: 15px;
  font-weight: normal;
  color: white;
  margin-top: 6px;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.loginInstructions {
  font-size: 13px;
  font-weight: normal;
  color: white;
  text-align: left;
}

.bottomtext {
  margin-top: 59px;
  font-size: 12px;
  font-weight: normal;
  color: #230c0c;
  text-align: justify;
  width: 556px;
}

.modal {
  top: '50%';
  left: '50%';
  right: 'auto';
  bottom: 'auto';
  margin-right: '-50%';
  transform: 'translate(-50%, -50%)';
}

.logincontainer{
  display: contents;
}

.alerticon {
  height: 30px;
}
