* {
  font-weight: 700;
  color: #fff;
}

body {
  margin: 0;
  font-family: "Benton Sans Low-DPI",Arial,Helvetica,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: black;
  //background-image: url(../src/assets/BG.svg);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #7b7676 inset;
}

::-webkit-input-placeholder {
  text-align: left;
  line-height: normal;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: left;  
  line-height: normal;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: left;  
  line-height: normal;
}

:-ms-input-placeholder {  
  text-align: left; 
  line-height: normal;
}