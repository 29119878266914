@import "../mixins";
@import "../variables";

.loginTitle {
  @include display-flex();
  @include justify-content-center();
  font-size: 20px;
  margin-bottom: 45px;
  color: #a3d624;
}

.loginLogo {
  height: 143px;
  cursor: pointer;
}